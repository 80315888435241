<template>
  <div class="con-wrap">
    <DeptInboxInfoPopup
      v-if="pop.isShow"
      @close="pop.isShow = false"
    ></DeptInboxInfoPopup>
    <CarrotTitle title="우리 부서수신함">
      <button class="btn-info ml-140" @click="pop.isShow = true">info</button>
      - 각 부서에서 담당하는 결재 목록이 보여집니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
              <tr>
                <th width="180">등록일</th>
                <td>
                  <CarrotDatePicker
                    class="inline-datepicker"
                    v-model="search.info.sdate"
                  ></CarrotDatePicker>
                  ~
                  <CarrotDatePicker
                    class="inline-datepicker"
                    v-model="search.info.edate"
                  ></CarrotDatePicker>
                </td>
                <th width="180">문서번호</th>
                <td>
                  <input
                    type="text"
                    class="w-100per"
                    v-model="search.info.doc_num"
                  />
                </td>
              </tr>
              <tr>
                <th width="180">양식</th>
                <td>
                  <DocumentFormSelector
                    v-model="search.temp.forms"
                  ></DocumentFormSelector>
                </td>
                <th width="180">기안자</th>
                <td>
                  <CarrotStaff
                    :idx_office="search.info.idx_office"
                    :idx_team="search.info.idx_team"
                    v-model="search.info.idx_staff"
                  ></CarrotStaff>
                </td>
              </tr>
              <tr>
                <th width="180">본부</th>
                <td>
                  <CarrotSector
                    v-model="search.info.idx_sector"
                    class="w-200px"
                  ></CarrotSector>
                  <CarrotDept
                    :idx_sector="search.info.idx_sector"
                    v-model="search.info.idx_office"
                  ></CarrotDept>
                </td>
                <th width="180">팀</th>
                <td>
                  <CarrotTeam
                    :idx_sector="search.info.idx_sector"
                    :idx_office="search.info.idx_office"
                    v-model="search.info.idx_team"
                  ></CarrotTeam>
                </td>
              </tr>
              <tr>
                <th width="180">제목</th>
                <td>
                  <input
                    type="text"
                    class="w-100per"
                    v-model="search.info.title"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn-default float-right mt-10"
            @click="docs.getDocuments()"
          >
            검색
          </button>
          <div class="clear"></div>

          <div class="mt-40 mb-20">
            <select
              class="w-200px"
              v-model="search.info.doc_state"
              @change="docs.getDocuments()"
            >
              <option value="">전체</option>
              <option value="대기">대기</option>
              <option value="진행중">진행중</option>
              <option value="완료">완료</option>
              <option value="반려">반려</option>
            </select>
            <select
              class="w-200px ml-20"
              v-model="search.info.doc_check"
              @change="docs.getDocuments()"
            >
              <option value="">미확인+확인완료</option>
              <option value="미확인">미확인</option>
              <option value="확인완료">확인완료</option>
            </select>
            <button class="btn-default float-right" @click="docs.checkArray()">
              확인완료
            </button>
            <div class="clear"></div>
          </div>

          <table class="table-col">
            <colgroup>
              <col width="35" />
              <col width="55" />
              <col width="100" />
              <col width="60" />
              <col width="110" />
              <col width="130" />
              <col width="300" />
              <col width="110" />
              <col width="125" />
              <col width="75" />
              <col width="110" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <label
                    ><input
                      type="checkbox"
                      v-model="docs.checkall"
                      @change="docs.setCheckAll"
                  /></label>
                </th>
                <th>No</th>
                <th>등록일</th>
                <th>상태</th>
                <th>문서번호</th>
                <th>양식</th>
                <th>제목</th>
                <th>기안자</th>
                <th>본부</th>
                <th>확인</th>
                <th>확인자</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in docs.list" :key="d.idx">
                <td>
                  <label><input type="checkbox" v-model="d.check" /></label>
                </td>
                <td>{{ d.num }}</td>
                <td>{{ d.cdate }}</td>
                <td>{{ d.status }}</td>
                <td class="txt-ellipsis">{{ d.code }}</td>
                <td class="txt-ellipsis">{{ d.form }}</td>
                <td>
                  <router-link
                    target="_blank"
                    :to="'/documents/Document/' + d.idx"
                    class="table-title"
                  >
                    <span class="txt-left line-clamp-1">{{ d.title }}</span>
                    <span class="list-num ml-5" v-if="d.cnt_comment > 0"
                      >({{ d.cnt_comment }})</span
                    >
                  </router-link>
                </td>
                <td class="txt-ellipsis">{{ d.ename }}({{ d.kname }})</td>
                <td class="txt-ellipsis">{{ d.office_name }}</td>
                <td>
                  <span
                    v-if="d.check_staff == 0"
                    @click="docs.check(d, 'Y')"
                    class="btn-view color-1"
                    >미확인</span
                  >
                  <span
                    v-if="d.check_staff > 0"
                    @click="docs.check(d, 'N')"
                    class="btn-view"
                    >확인완료</span
                  >
                </td>
                <td class="txt-ellipsis">
                  <span v-if="d.check_staff == 0" @click="docs.check(d, 'Y')"
                    >-</span
                  >
                  <span v-if="d.check_staff > 0" @click="docs.check(d, 'N')"
                    >{{ d.check_ename }}({{ d.check_kname }})</span
                  >
                </td>
              </tr>
              <tr v-if="docs.list.length == 0">
                <td colspan="11">데이터가 없습니다.</td>
              </tr>

              <!-- 
                            <tr>
                                <td>
                                    <label><input type="checkbox" id="" name="" value=""></label>
                                </td>
                                <td>00</td>
                                <td>2019-00-00</td>
                                <td>완료</td>
                                <td>A201908328</td>
                                <td>지출결의서(회사)</td>
                                <td class="txt-left">
                                    <span class="btn-view">한국전력공사 계약이행보증보험 납부 / 에듀</span>
                                    <span class="list-num ml-5">(5)</span>
                                </td>
                                <td>Cailyn(고아람)</td>
                                <td>이러닝융합서비스</td>
                                <td><span class="btn-view color-1">미확인</span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>
                                    <label><input type="checkbox" id="" name="" value=""></label>
                                </td>
                                <td>00</td>
                                <td>2019-00-00</td>
                                <td>진행중</td>
                                <td>A201908328</td>
                                <td>상품권 사용신청서</td>
                                <td class="txt-left">
                                    <span class="btn-view">직원 기념일 상품권 신청</span>
                                    <span class="list-num ml-5">(5)</span>
                                </td>
                                <td>Cailyn(고아람)</td>
                                <td>이러닝융합서비스</td>
                                <td><span class="btn-view">확인완료</span></td>
                                <td>Min K(김민정)</td>
                            </tr>
                            <tr>
                                <td>
                                    <label><input type="checkbox" id="" name="" value=""></label>
                                </td>
                                <td>00</td>
                                <td>2019-00-00</td>
                                <td>완료</td>
                                <td>A201908328</td>
                                <td>상품권 사용신청서</td>
                                <td class="txt-left">
                                    <span class="btn-view">직원 기념일 상품권 신청</span>
                                    <span class="list-num ml-5">(5)</span>
                                </td>
                                <td>Cailyn(고아람)</td>
                                <td>이러닝융합서비스</td>
                                <td><span class="btn-view">확인완료</span></td>
                                <td>Min K(김민정)</td>
                            </tr> -->
            </tbody>
          </table>
          <CarrotPaging
            v-if="docs.total > 0"
            :total="docs.total"
            :list_per_page="50"
            v-model="search.info.page"
            @change="docs.getDocuments"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import axios from "@/plugins/axios.js";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotSector from "@/components/common/CarrotSector.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import CarrotStaff from "@/components/common/CarrotStaff.vue";
import DeptInboxInfoPopup from "@/components/popup/payment/DeptInboxInfoPopup.vue";
import DocumentFormSelector from "@/components/input/DocumentFormSelector.vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  layout: "documents",
  components: {
    DeptInboxInfoPopup,
    CarrotDatePicker,
    CarrotDept,
    CarrotTeam,
    CarrotStaff,
    DocumentFormSelector,
    CarrotSector,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const store = useStore();
    const search = reactive({
      info: {
        page: 1,
        sdate: "",
        edate: "",
        idx_sector: 0,
        idx_office: 0,
        idx_team: 0,
        idx_staff: 0,
        doc_state: "",
        doc_type: "",
        doc_num: "",
        doc_check: "",
        title: "",
      },
      init: false,
      temp: {
        forms: {
          category: "",
          formname: "",
        },
      },
      dateToString(ndate) {
        return [
          ndate.getFullYear(),
          (ndate.getMonth() + 1 > 9 ? "" : "0") + (ndate.getMonth() + 1),
          (ndate.getDate() > 9 ? "" : "0") + ndate.getDate(),
        ].join("-");
      },
      getDocumentForm: () => {
        let params = {
          tgdate: new Date(),
        };
        axios
          .get("/rest/documents/getForms", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              docs.ca_list = res.data.list;
            } else {
              toast.error(res.data.err_msg + "1111111");
            }
          });
      },
    });

    const pop = reactive({
      // Popup 노출 여부
      isShow: false,
    });
    const docs = reactive({
      list: [],
      total: 0,
      checkall: false,
      check: (itm, action) => {
        let params = { idx: itm.idx, check: action };
        axios.post("/rest/documents/setCheck", params).then((res) => {
          if (res.data.err == 0) {
            docs.getDocuments();
          } else {
            console.info(res.data.err_msg);
          }
        });
      },
      checkArray: () => {
        let list = [];
        for (let i = 0; i < docs.list.length; i++) {
          if (docs.list[i].check == true) {
            list.push(docs.list[i].idx);
          }
        }
        let params = { list: list, check: "Y" };
        axios.post("/rest/documents/setCheckAll", params).then((res) => {
          if (res.data.err == 0) {
            docs.getDocuments();
          } else {
            console.info(res.data.err_msg);
          }
        });
      },
      setCheckAll: () => {
        for (let i = 0; i < docs.list.length; i++) {
          docs.list[i].check = docs.checkall;
        }
      },
      getDocuments: () => {
        if (search.init == false) {
          return;
        }
        let params = search.info;
        params.form_cat = search.temp.forms.category;
        params.form_name = search.temp.forms.formname;
        params.is_office = "Y";

        axios
          .get("/rest/documents/getDocuments", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              let list = res.data.list;
              for (let i = 0; i < list.length; i++) {
                list[i]["check"] = false;
              }
              docs.list = list;
              docs.total = res.data.total;
            } else {
              if (!store.state.isToastVisible) {
                store.commit("setisToastVisible", true);
                toast.error(res.data.err_msg, {
                  onClose: () => {
                    store.commit("setisToastVisible", false);
                  },
                });
              }
              router.push({
                name: "/login",
              });
              // 에러를 반환하고  documents-AllDocumentList 이동 되면 안되서 login으로 변경 -eruda
              //   router.push({
              //     name: "documents-AllDocumentList",
              //   });
            }
          });
      },
      getCheck: () => {
        let cat = "부서";
        let params = { cat: cat };

        axios
          .get("/rest/documents/checkIcon", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              console.info(res.data.err);
            } else {
              console.info(res.data.err_msg);
            }
          });
      },
    });

    onMounted(() => {
      // Mounted
      docs.getCheck();
      let today = new Date();
      let sdate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
      let edate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      search.info.sdate = search.dateToString(sdate);
      search.info.edate = search.dateToString(edate);

      search.init = true;
      docs.getDocuments();
    });

    return { pop, docs, search };
  },
};
</script>

<style lang="scss" scoped>
.table-title {
  display: flex;
}
</style>
