<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div>
                <p class="title">우리 부서수신함 양식안내</p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="90">
                        <col width="*">
                        <col width="100">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>양식</th>
                            <th>우리 부서수신함</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowspan="8">경비</th>
                            <td class="border-l txt-left">지출결의서</td>
                            <td rowspan="8" class="border-l">경영기획실</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">지출결의서(개인경비)</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">지출결의서(법인)</td>
                        </tr>
                        <tr> 
                            <td class="txt-left border-l">지출결의서(수당)</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">구매요청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">상품권 사용신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">결제 취소/환불 요청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">강사급여 추가 신청서</td>
                        </tr>                        
                        <tr>
                            <th rowspan="3">교재</th>
                            <td class="border-l txt-left">연구교재 구입신청서</td>
                            <td rowspan="3" class="border-l">경영기획실</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">외부교재 구입신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">제본교재 신청서</td>
                        </tr>
                        <tr>
                            <th rowspan="3">인센티브</th>
                            <td class="border-l txt-left">추가 수당 신청서</td>
                            <td rowspan="3" class="border-l">경영기획실</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">전화영어 인센티브 신청서(B2B)</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">야근수당 신청서</td>
                        </tr>
                        <tr>
                            <th rowspan="5">개발</th>
                            <td class="border-l txt-left">개발요청서</td>
                            <td rowspan="5" class="border-l">솔루션개발<br>연구소</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">홍보자료 제작요청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">교재개발요청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">개인정보 요청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">고객사 등록 요청서</td>
                        </tr>
                        <tr>
                            <th rowspan="2">서비스지원</th>
                            <td class="border-l txt-left">Compain 처리 보고서</td>
                            <td rowspan="2" class="border-l">스마트러닝<br>사업본부</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">PP처리보고서</td>
                        </tr>
                        <tr>
                            <th rowspan="5">교육</th>
                            <td class="border-l txt-left">교육신청서</td>
                            <td rowspan="19" class="border-l">경영기획실</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">사외위탁교육 서약서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">사외교육 보고서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">필독도서 보고서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">생활멘토 활동일지</td>
                        </tr>
                        <tr>
                            <th rowspan="6">휴가</th>
                            <td class="border-l txt-left">Outing 신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">연차신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">휴직신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">멘토링 불참 신청서</td>
                        </tr>
                        <!-- <tr>
                            <td class="txt-left border-l">Time-Out 신청서</td>
                        </tr> -->
                        <tr>
                            <td class="txt-left border-l">재택근무 신청서</td>
                        </tr>
                        <tr>
                            <th rowspan="2">출장</th>
                            <td class="border-l txt-left">출장신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">출장보고서</td>
                        </tr>
                        <tr>
                            <th rowspan="3">복리후생</th>
                            <td class="border-l txt-left">대출신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">동호회 승인 요청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">차량 사용 신청서</td>
                        </tr>
                        <tr>
                            <th rowspan="4">인사</th>
                            <td class="border-l txt-left">One Page Proposal</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">아르바이트 신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">인재채용 신청서</td>
                        </tr>
                        <tr>
                            <td class="txt-left border-l">인사기안 신청서</td>
                            <td class="border-l">피플팀</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'DeptInboxInfoPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>